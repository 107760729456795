<template>
  <div class="container">
    <header><h1  class="header-1">NORTH SOUTH UNIVERSITY ADVISING COURSE LIST</h1></header>
      <input type="text" v-model="searchQuery1" placeholder=" Course">
      <input type="text" v-model="searchQuery2" placeholder=" Faculty">
      
      <div class="canvas">
        <table class="center">
        <thead>
          <tr>
            <th>Course</th>
            <th>Section</th>
            <th>Faculty</th>
            <th>Time</th>
            <th>Room</th>
            <th>Semester</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in paginatedData" :key="item.FIELD1">
            <td>{{ item.Course }}</td>
            <td>{{ item.Section }}</td>
            <td>{{ item.Faculty }}</td>
            <td>{{ item.Time }}</td>
            <td>{{ item.Room }}</td>
            <td>{{item.Semester}}</td>
          </tr>
        </tbody>
      </table>
      </div>
    <div class="pagination">
      <button :disabled="currentPage === 1" @click="previousPage">Previous</button>
      <span>{{ currentPage }} / {{ totalPages }}</span>
      <button :disabled="currentPage === totalPages" @click="nextPage">Next</button>
    </div>
        <footer class="footer">
      <div class="container">
        <b><p>Developed by <a href="https://www.facebook.com/sbbhuiyan.basir/">Samiul Basir</a></p></b>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery1: '',
      searchQuery2: '',
      jsonData: [],
      currentPage: 1,
      itemsPerPage: 20   // Number of items per page
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      fetch('data.json')
        .then(response => response.json())
        .then(data => {
          this.jsonData = data;
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    }
  },
  computed: {
    filteredData() {
    if (this.searchQuery1 !== '' && this.searchQuery2 !== '') {
        // Both Course and Faculty search queries provided
        return this.jsonData.filter(item => {
          const courseMatch = item.Course.toLowerCase().includes(this.searchQuery1.split(" ").join("").toLowerCase());
          const facultyMatch = item.Faculty.toLowerCase().includes(this.searchQuery2.split(" ").join("").toLowerCase());
          return (courseMatch && facultyMatch);
        });
      } else if (this.searchQuery1 !== '') {
        // Only Course search query provided
        return this.jsonData.filter(item => {
          return item.Course.toLowerCase().includes(this.searchQuery1.split(" ").join("").toLowerCase());
        });
      } else if (this.searchQuery2 !== '') {
        // Only Faculty search query provided
        return this.jsonData.filter(item => {
          return item.Faculty.toLowerCase().includes(this.searchQuery2.split(" ").join("").toLowerCase());
        });
      }
      // If both search fields are empty, show all data
      return this.jsonData;
    },
    totalPages() {
      return Math.ceil(this.filteredData.length / this.itemsPerPage);
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredData.slice(startIndex, endIndex);
    }
  },
   watch: {
    searchQuery1(newValue) {
      if (newValue === '') {
        this.currentPage = 1;
      }
    },
     searchQuery2(newValue) {
      if (newValue === '') {
        this.currentPage = 1;
      }
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Oswald&family=Poppins&display=swap');
header h1 {
  font-size: 32px;
  font-family:'Oswald', sans-serif; 
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;   
}
header .header-1{
  background-image: repeating-radial-gradient(farthest-side at 5px 5px,#553c9a, #ee4b2b, #553c9a);
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  
}

table {
  border-collapse: collapse;
  width: 80%;
  margin-top: 2px;
  font-weight: bold;

}

th, td {
  border: 1px solid black;
  padding: 9px;
}

th {
  background-color: #f2f2f2;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

input {
  display: inline-flex;
  
  font-weight: bold;
  width: 150px;
  margin: 5px auto;

  padding-right: 20%;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: white  no-repeat;

  font-size: 15px;
  border: none;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 200px;
  margin: 20px auto;
  padding: 10px 45px;
  font-weight: bold;

}

.pagination button {
  margin: 0 5px;
}

.footer {
  background-color: transparent;
  padding: 10px;
  text-align: center;
  font-weight: bold;
}
.footer a{
  text-decoration: none;
}

.footer p {
  margin: 0;
}

.canvas{
  
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
  margin: 10px;
}

@media screen and (max-width: 450px) {
  header h1 {
    font-size: 29px;
  }
}
</style>
