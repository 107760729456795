<template>

  <AdvisingList />
</template>

<script>
import AdvisingList from './components/AdviseTable.vue'

export default {
  name: 'App',
  components: {
    AdvisingList
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&family=Rubik+Bubbles&display=swap');
#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; 
  
}
</style>
